@import "styles/includes";

.select {
  display: flex;
  button {
    font-weight: 400;
    color: $g400;

    &:disabled {
      font-weight: 700;
      color: $white;
      cursor: default;
    }

    + button {
      &::before {
        display: inline-block;
        width: 1px;
        height: .75rem;
        margin: 0 .5rem;
        content: "";
        background: $g500;
      }
    }
  }
}
