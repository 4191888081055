// stylelint-disable selector-max-id

/* Make clicks pass-through */

#nprogress {
  pointer-events: none;

  .bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031;
    width: 100%;
    height: 2px;
    background: $gray;
  }


  /* Fancy blur effect */
  .peg {
    position: absolute;
    right: 0;
    display: block;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px $gray, 0 0 5px $gray;
    opacity: 1;
    transform: rotate(3deg) translate(0, -4px);
  }

  /* Remove these to get rid of the spinner */
  .spinner {
    position: fixed;
    top: 15px;
    right: 15px;
    z-index: 1031;
    display: block;
  }

  .spinner-icon {
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    border: solid 2px transparent;
    border-top-color: $gray;
    border-left-color: $gray;
    border-radius: 50%;
    animation: nprogress-spinner .4s linear infinite;
  }
}

.nprogress-custom-parent {
  position: relative;
  overflow: hidden;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@keyframes nprogress-spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
