
.transition {
  transition: .2s ease-in-out;
}

.transition-4s {
  transition: .4s ease-in-out;
}

.spin {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

@keyframes blinky {
  0% { opacity: 1; }
  50% { opacity: .6; }
  100% { opacity: 1; }
}
