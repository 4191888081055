// stylelint-disable declaration-no-important

// Common values

// Sass list not in variables since it's not intended for customization.
// stylelint-disable-next-line scss/dollar-variable-default
$positions: static, relative, absolute, fixed, sticky;

@each $position in $positions {
  .ps-#{$position} { position: $position !important; }
}


.z1 { z-index: $z1; }
.z2 { z-index: $z2; }
.z3 { z-index: $z3; }
.z4,
.z-navbar {
  z-index: $z4;
}

.z5,
.z-popper,
.z-dropdown,
.z-tooltip {
  z-index: $z5;
}

.z6,
.z-modal-backdrop {
  z-index: $z6;
}
.z7,
.z-modal {
  z-index: $z7;
}
