// stylelint-disable declaration-no-important, selector-pseudo-class-no-unknown
@import "styles/variables";
@import "styles/mixins";

.bodyOpen {
  // height: 100% !important;
  overflow: hidden !important;

  :global(.surface) {
    filter: blur(5px);
  }
}
.htmlOpen {
  height: 100% !important;
  margin-top: 0 !important;
  overflow: hidden !important;
  // overflow-y: scroll !important;
}

.overlay {
  position: fixed;
  inset: 0;
  z-index: $z6;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  // @include gradient-directional($start-color: $gray-700, $end-color: $gray-800, $deg: 45deg);
  @include gradient-directional(rgba($white, .85), rgba($white, 1), 135deg);

  &:global(.ReactModal__Overlay) {
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  &:global(.ReactModal__Overlay--after-open) {
    opacity: 1;
  }

  &:global(.ReactModal__Overlay--before-close) {
    opacity: 0;
  }
}

.potal {
  display: block;
}

.content {
  width: 100%;
  min-height: 100%;
  // max-width: 1000px;
  // max-width: 700px;
  // margin: 3rem auto;
  transition: .2s ease;
}

.closeBtn {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  z-index: $z7;
  width: 2rem;
  height: 2rem;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    background: $g600;
    border-radius: 4px;
    transition: $transition-base;
  }

  &::before { transform: rotate(45deg); }
  &::after { transform: rotate(-45deg); }

  &:hover,
  &:focus,
  &:active {
    &::before,
    &::after {
      height: 2px;
      background-color: $g900;
    }
    &::before { transform: rotate(135deg); }
    &::after { transform: rotate(45deg); }
  }
}
