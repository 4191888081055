// stylelint-disable declaration-no-important

//
// Text
//

.text-monospace { font-family: $font-family-monospace; }

// Alignment

.text-justify  { text-align: justify !important; }
.text-nowrap   { white-space: nowrap !important; }
.text-truncate { @include text-truncate; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left   { text-align: left !important; }
    .text#{$infix}-right  { text-align: right !important; }
    .text#{$infix}-center { text-align: center !important; }
  }
}

// Transformation

.text-lowercase  { text-transform: lowercase !important; }
.text-uppercase  { text-transform: uppercase !important; }
.text-capitalize { text-transform: capitalize !important; }

// Weight and italics

// .font-weight-light  { font-weight: $font-weight-light !important; }
// .font-weight-normal { font-weight: $font-weight-normal !important; }
// .font-weight-bold   { font-weight: $font-weight-bold !important; }
.font-italic        { font-style: italic !important; }

// Contextual colors

.text-white { color: $white !important; }

// @each $color, $value in $theme-colors {
//   @include text-emphasis-variant(".text-#{$color}", $value);
// }

// .text-body { color: $body-color !important; }
// .text-muted { color: $text-muted !important; }

// .text-black-50 { color: rgba($black, .5) !important; }
// .text-white-50 { color: rgba($white, .5) !important; }

// Misc

.text-hide {
  @include text-hide($ignore-warning: true);
}


// font size
.tx-10 { font-size: 10px; }
.tx-12 { font-size: 12px; }
.tx-14 { font-size: .875rem; }
.tx-16 { font-size: 1rem; }
.tx-18 { font-size: 1.125rem; }
.tx-20 { font-size: 1.25rem; }
.tx-22 { font-size: 1.375rem; }
.tx-24 { font-size: 1.5rem; }
.tx-28 { font-size: 1.75rem; }
.tx-32 { font-size: 2rem; }
.tx-34 { font-size: 2.125rem; }
.tx-42 { font-size: 2.625rem; }

@include media-breakpoint-up(md) {
  .tx-md-32 { font-size: 2rem !important; }
}

// color
.tx-black { color: $black; }
.tx-white { color: $white; }
.tx-notice { color: $color-notice; }
.tx-warning { color: $color-warning; }
.tx-success { color: $color-success; }
.tx-primary { color: $primary; }
// gray
.tx-gray { color: $gray; }
.tx-g100 { color: $g100; }
.tx-g200 { color: $g200; }
.tx-g300 { color: $g300; }
.tx-g400 { color: $g400; }
.tx-g500 { color: $g500; }
.tx-g600 { color: $g600; }
.tx-g700 { color: $g700; }
.tx-g800 { color: $g800; }
.tx-g900 { color: $g900; }
.tx-gish { color: $gish; }
.tx-gish-light { color: $gish-light; }

// line height
.tx-l1 { line-height: 1; }
.tx-l12 { line-height: 1.2; }
.tx-l14 { line-height: 1.4; }
.tx-l16 { line-height: 1.6; }

// align
.tx-right { text-align: right; }
.tx-left { text-align: left; }
.tx-center { text-align: center; }

// font weight
.tx-w300 { font-weight: 300 !important; }
.tx-w400 { font-weight: 400 !important; }
.tx-w600 { font-weight: 600 !important; }
.tx-w700 { font-weight: 700 !important; }
.tx-w800 { font-weight: 800 !important; }

.tx-bold {
  // stylelint-disable-next-line font-weight-notation
  font-weight: bold;
  font-weight: 600;
}

.tx-oswald { font-family: $oswald; }

// color
@include text-emphasis-variant(".text-black", $black);

.tx-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tx-ellipsis2 {
  display: -webkit-box;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tx-ellipsis3 {
  display: -webkit-box;
  max-height: 5em;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.tx-linethrough {
  text-decoration: line-through;
}
