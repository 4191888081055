@import "styles/variables";

.nav {
  height: 2.5rem;
  overflow: hidden;
  // margin-top: 5rem;
  background-color: $g100;
  border-top: 1px solid $g200;
  border-bottom: 1px solid $g200;

  ol {
    height: 3.5rem;
    margin: 0 auto;
    overflow: auto;
    white-space: nowrap;
    list-style: none;
    -webkit-overflow-scrolling: touch;
  }

  li {
    display: inline-block;
    font-size: .85rem;
    line-height: 2.5rem;

    + li::before {
      padding-right: 1rem;
      padding-left: 1rem;
      color: $g300;
      content: "/";
    }
  }
}
