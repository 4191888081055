
.list-inline {
  padding-left: 0;
  list-style: none;

  li {
    display: inline-block;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}
