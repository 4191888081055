// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

/* stylelint-disable */
.container,
.container-md,
.container-sm,
.container-fluid {
  width: 100%;
  max-width: 62.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

.container { max-width: 71.25rem; }
.container-sm { max-width: 43.75rem; }
.container-md { max-width: 60rem; }

// Row
//
// Rows contain and clear the floats of your columns.

.row {
  @include make-row();
}

// Remove the negative margin from default .row, then the horizontal padding
// from all immediate children columns (to prevent runaway style inheritance).
.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns();
