.cookie-container {
  background: rgba(0, 0, 0, .8) !important;
  align-items: center !important;
  a {
    color: #fff;
    text-decoration: underline;
  }
}
.cookie-content {
  margin: 0 1rem !important;
  padding: 0 !important;
}
.cookie-button-wrapper {
  margin: 0 3rem;
}
.cookie-button {
  margin: 0 !important;
  padding: .5rem 2rem !important;
  font-size: .875rem !important;
  color: #fff !important;
  background: none !important;
  border: 1px solid #fff !important;
  border-radius: 20px !important;
  transition: all .2s ease;
  &:hover {
    color: #000 !important;
    background: #fff !important;
    border-color: none !important;
  }
}
@media screen and (max-width: 575px) {
  .cookie-content {
    margin: 0 !important;
  }
  .cookie-button-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 0 2rem 0;
  }
}
