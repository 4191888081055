.btn-chromeless {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  &:focus {
    outline: 0;
  }
}

.btn-link {
  padding: 0;
  color: $link-color;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: 0;

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
}

.btn-block {
  display: block;
  width: 100%;
}
