// stylelint-disable declaration-no-important
@import "styles/variables";

.container {
  position: relative;
  width: 100%;
  height: 100%;
  height: 100vh;
  overflow-x: hidden;
}

.hero {
  position: relative;
  padding: 1.5rem 0;

  @media (min-width: $screen-md) {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
.heroBg {
  position: absolute;
  top: -10rem;
  right: 0;
  left: 0;
  z-index: -1;
  height: 20rem;
  background: $primary;
  transition: .4s ease;
  transform: skewY(5deg);

  &.signup {
    transform: skewY(-5deg);
  }

  @media (min-width: $screen-md) {
    inset: 0 450px 0 -10rem;
    height: auto;
    transform: skew(7deg);

    &.signup {
      right: -10rem;
      left: 450px;
      transform: skew(-7deg);
    }
  }

  @media (min-width: $screen-lg) {
    right: 550px;
    &.signup {
      left: 550px;
    }
  }
}
.logo {
  position: relative;
  width: 8.5rem;
  height: 6.125rem;
  @media (min-width: $screen-md) {
    width: 10rem;
    height: 7.25rem;
  }
  @media (min-width: $screen-lg) {
    width: 13rem;
    height: 9.375rem;
  }
}
.form {
  width: 100%;
  max-width: 500px;
  padding: 2rem;
  margin-top: 2rem;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: $screen-md) {
    width: 400px;
    margin-top: 0;
  }
  @media (min-width: $screen-lg) {
    width: 500px;
  }
}

.facebook,
.twitter,
.email,
.google {
  position: relative;
  display: block;
  width: 100%;
  height: 3.5rem;
  padding-right: 1rem;
  padding-left: 3rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 3.5rem;
  color: $white;
  text-align: left;
  text-decoration: none !important;
  cursor: pointer;
  border: 0;
  border-radius: 4px;
  transition: .2s ease;

  &:hover {
    opacity: .9;
  }

  .icon {
    position: absolute;
    top: 0;
    left: 1rem;
    line-height: 3.5rem;
  }
}

.small {
  color: rgba($white, .6);
}

.facebook {
  background-color: $brand-facebook;
}
.twitter {
  background-color: $brand-twitter;
}
.google {
  background-color: $brand-google;
}
.email {
  color: $white;
  background-color: $g600;
}

.emailInput {
  width: 100%;
  padding: .5rem .25rem;
  border: 0;
  border-bottom: 1px solid $g500;
  box-shadow: none;
  transition: $transition-base;

  &:focus {
    border-color: $g800;
    outline: 0;
  }
}

.checkInbox {
  width: 100%;
  max-width: 35rem;
  padding: 5rem 2rem;
  margin-right: auto;
  margin-left: auto;
}

.inboxImage {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  text-align: center;
}
