
@import "styles/variables";
.switch {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 2rem;
  vertical-align: middle;
  background: transparent;
  border: 0;
  border-radius: 99em;
  transition: $transition-base;

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba($g900, .25);
  }

  &:disabled {
    opacity: .6;
  }
}

.slider {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: $g500;
  border-radius: 99em;
  transition: $transition-base;

  &::before {
    position: absolute;
    bottom: .25rem;
    left: .25rem;
    width: 1.5rem;
    height: 1.5rem;
    content: "";
    background-color: $white;
    border-radius: 99em;
    transition: $transition-base;
  }
}

.on,
.off {
  padding: 0 .5rem;
  color: $white;
}

.on {
  display: none;
  text-align: left;
}
.off {
  text-align: right;
}

.slider.active {
  background-color: $primary;

  .on {
    display: block;
  }
  .off {
    display: none;
  }

  &::before {
    transform: translateX(2rem);
  }
}

//
// .btn {
//   padding-right: 1rem;
//   padding-left: 1rem;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//
//   &:disabled {
//     opacity: 1;
//   }
//
//   + .btn {
//     margin-left: -1px;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//   }
//
//   &:last-child {
//     border-radius: 0 99em 99em 0;
//   }
// }
//
// .default {
//   color: $g800;
//   border-color: $g600;
//
//   &:hover,
//   &:active {
//     color: $white;
//     background: $g600;
//   }
// }
//
// .active {
//   padding-right: 1.5rem;
//   padding-left: 1.5rem;
// }
