@import "styles/variables";

.wrap {
  position: relative;
  line-height: 1;
}

.wrap,
.canvas {
  width: 100%;
  max-width: 100%;
}
.canvas {
  transform-origin: bottom;
}

.canvas:hover {
  + .time {
    opacity: 0;
  }
}
.time {
  position: absolute;
  right: 2px;
  bottom: 2px;
  display: block;
  padding: .25rem;
  font-size: 10px;
  color: $white;
  background: rgba($black, .6);
  pointer-events: none;
  transition: ease-in-out .2s;
}
