// stylelint-disable declaration-no-important

.shadow-sm { box-shadow: $box-shadow-sm !important; }
.shadow { box-shadow: $box-shadow !important; }
.shadow-lg { box-shadow: $box-shadow-lg !important; }
.shadow-none { box-shadow: none !important; }

.shadow-coverart {
  box-shadow: 0 0 0 2px rgba(#fff, .3), 0 0 0 4px rgba($g500, .05), 0 10px 30px rgba($g500, .05), 0 1.75rem 2rem -1.5rem rgba($g900, .4);
}

.shadow-avatar {
  box-shadow: 0 0 0 2px rgba(#fff, .3), 0 0 0 4px rgba($g500, .05), 0 10px 30px rgba($g500, .05), 0 2.5rem 3rem -1.75rem rgba($g900, .4);
}
