
@import "styles/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  height: 70vh;
  padding: 10rem 0;
  color: $white;
  background: transparent center center no-repeat;
  background-size: cover;
}

.code {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 4rem;
  font-weight: 800;
  color: $g800;
  background: $white;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
}

.h1 {
  font-size: 3rem;
  text-shadow: 0 2px 10px rgb(0 0 0 / 30%);
}
