// stylelint-disable declaration-no-important

.noborder {
  border: 0 !important;
}

.radius { border-radius: .25rem !important; }
.circle { border-radius: 50% !important; }
.r0 { border-radius: 0 !important; }

.hr {
  margin: 1rem auto;
  border-bottom: 1px solid $g300;
}
.hr-thin {
  margin: 1rem auto;
  border-bottom: 1px solid $g200;
}

.border-b100 {
  border-bottom: 1px solid $g100;
}
.border-b200 {
  border-bottom: 1px solid $g200;
}
.border-b300 {
  border-bottom: 1px solid $g300;
}
