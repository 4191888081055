@import "styles/variables";
@import "styles/mixins";

.player {
  position: fixed;
  bottom: 0;
  z-index: $z4;
  width: 100%;
  transition: $transition-base;
  transform: translateY(120%);

  &.show {
    transform: translateY(0);
  }
}

.container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 980px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  margin-right: auto;
  margin-left: auto;
  background: rgba($white, .95);
  border-radius: 4px 4px 0 0;
  box-shadow: 0 0 0 1px rgba($black, .05), 0 0 4px rgba($black, .15);
  backdrop-filter: saturate(180%) blur(10px);
}

.btn {
  flex: 0 0 auto;
  padding: 1rem;
  margin-right: .25rem;
  margin-left: .25rem;
  background: transparent;
  border: 0;
  &:focus {
    outline: 0;
  }

  @media (min-width: $screen-md) {
    padding: 1rem .75rem;
  }
  @media (min-width: $screen-lg) {
    padding: 1rem;
  }
}

.btn:not([disabled]) {
  cursor: pointer;
}

.vr {
  width: 1px;
  height: 1.5rem;
  margin-right: .25rem;
  margin-left: .25rem;
  background: $g200;
}

.play {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: transparent;
  border-radius: 99em;
  box-shadow: 0 0 0 2px $g300;

  &:focus {
    outline: 0;
  }
}

.btn:disabled {
  opacity: .3;
}

.forward,
.backward {
  display: none;
  @media (min-width: $screen-md) {
    display: block;
  }
  img {
    width: 1.75rem;
  }
}

.repeat {
  opacity: .6;

  &.active {
    opacity: 1;
  }
}

.playing {
  display: flex;
  align-items: center;
  width: 15rem;
  min-width: 0;
  margin-left: 1rem;
  overflow: hidden;
  line-height: 1;

  @include media-breakpoint-down(sm) {
    flex: 1 1 auto;
  }
}

.coverart {
  display: block;
  width: 2rem;
  height: 2rem;
  margin-right: .5rem;

  img {
    max-width: 100%;
  }
}

.waveform {
  // stylelint-disable-next-line declaration-no-important
  width: 22rem !important;
  // height: 2rem;

  @media (min-width: $screen-md) {
    // stylelint-disable-next-line declaration-no-important
    width: 15rem !important;
  }
  @media (min-width: $screen-lg) {
    // stylelint-disable-next-line declaration-no-important
    width: 22rem !important;
  }
}

.list {
  position: absolute;
  bottom: 100%;
  left: 0;
  display: none;
  flex-direction: column;
  width: 20rem;
  height: 500px;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba($black, .15);

  &::before,
  &::after {
    position: absolute;
    bottom: -16px;
    left: 1.25rem;
    width: 0;
    height: 0;
    pointer-events: none;
    content: "";
    border: 8px solid rgb(136 183 213 / 0%);
  }

  &::before {
    bottom: -20px;
    margin-left: -2px;
    border-width: 10px;
    border-top-color: $g300;
  }

  &::after {
    position: absolute;
    border-top-color: $white;
  }

  &.open {
    display: flex;
  }
}

.listTitle {
  flex: 0 0 auto;
  padding: .5rem .25rem .25rem;
  margin: 0 1rem;
  border-bottom: 1px solid $g400;
}

.listBody {
  flex: 1 1 auto;
  padding-bottom: 2rem;
  overflow-y: auto;
}

.listItem {
  &:hover,
  &:focus {
    background-color: $g100;
  }

  &:focus {
    outline: 0;
  }

  &:active,
  &.active {
    background-color: $g200;
  }
}
