@import "styles/includes";

$break: 960px !default;
$height: 3.25rem !default;

.play {
  rect,
  polygon {
    transition: $transition-base;
    fill: $g600;
  }
}

.fill {
  transition: $transition-base;
  fill: $g600;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0;
  color: $g800;
  cursor: pointer;
  background: rgba($white, .8);
  border: 0;
  border-radius: 99em;
  transition: .2s ease;

  &:hover {
    @include theme-gradient(1);

    .fill {
      fill: $white;
    }
  }

  &:disabled {
    cursor: default;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 1px rgba($gish, .5), 0 0 0 3px rgba($white, .3);
  }

  &:active {
    background-color: $g200;
  }

  &.loading {
    background: rgba($white, .6);
  }
}
