@import "styles/variables";

.container {
  position: fixed;
  top: 2rem;
  right: 2rem;
  left: 2rem;
  z-index: 1000;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 0 auto;
  margin: 0 auto;
  pointer-events: none;
}

.message {
  width: 100%;
  margin-bottom: .5rem;
  overflow: hidden;

  @media (min-width: $screen-md) {
    width: 35rem;
  }
}

.content {
  position: relative;
  display: flex;
  align-items: center;
  height: auto;
  padding: 1rem 1.5rem;
  overflow: hidden;
  pointer-events: all;
  background: rgba($g800, .9);
  border-radius: .25rem;
}

.p {
  flex: 1 1 auto;
  margin-bottom: 0;
  color: #fff;
}

.btn {
  display: flex;
  align-self: flex-end;
  padding: .5rem;
  margin: 0;
  overflow: hidden;
  font-size: 1.5rem;
  color: rgb(255 255 255 / 50%);
  pointer-events: all;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: 0;

  &:hover {
    color: rgb(255 255 255 / 90%);
  }
}

.life {
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  height: .25rem;
  background-image: linear-gradient(130deg, lighten($primary, 10%), $primary);
}
