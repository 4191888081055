/* stylelint-disable */
@font-face {
  font-family: "soundonlive";
  font-style: normal;
  font-weight: normal;
  src: url("/static/fonts/soundonlive.eot?4dkpc");
  src: url("/static/fonts/soundonlive.eot?4dkpc#iefix") format("embedded-opentype"), url("/static/fonts/soundonlive.ttf?4dkpc") format("truetype"), url("/static/fonts/soundonlive.woff?4dkpc") format("woff"), url("/static/fonts/soundonlive.svg?4dkpc#soundonlive") format("svg");
  font-display: swap;
}

[class^="solicon-"],
[class*=" solicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "soundonlive" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: none;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.solicon-box-bookmark::before {
  content: "\e901";
}

.solicon-tag-cord::before {
  content: "\e902";
}

.solicon-layers::before {
  content: "\e031";
}

.solicon-global::before {
  content: "\e052";
}

.solicon-microphone::before {
  content: "\e610";
}

.solicon-vynil::before {
  content: "\e613";
}

.solicon-heart-full::before {
  content: "\e900";
}

.solicon-agenda::before {
  content: "\e60e";
}

.solicon-alarm-clock::before {
  content: "\e62b";
}

.solicon-alert::before {
  content: "\e62c";
}

.solicon-align-center::before {
  content: "\e62d";
}

.solicon-align-justify::before {
  content: "\e62e";
}

.solicon-align-left::before {
  content: "\e62f";
}

.solicon-align-right::before {
  content: "\e630";
}

.solicon-anchor::before {
  content: "\e631";
}

.solicon-android::before {
  content: "\e632";
}

.solicon-angle-double-down::before {
  content: "\e633";
}

.solicon-angle-double-left::before {
  content: "\e634";
}

.solicon-angle-double-right::before {
  content: "\e635";
}

.solicon-angle-double-up::before {
  content: "\e636";
}

.solicon-angle-down::before {
  content: "\e637";
}

.solicon-angle-left::before {
  content: "\e638";
}

.solicon-angle-right::before {
  content: "\e639";
}

.solicon-angle-up::before {
  content: "\e63a";
}

.solicon-announcement::before {
  content: "\e63b";
}

.solicon-apple::before {
  content: "\e63c";
}

.solicon-archive::before {
  content: "\e63d";
}

.solicon-arrow-circle-down::before {
  content: "\e63e";
}

.solicon-arrow-circle-left::before {
  content: "\e63f";
}

.solicon-arrow-circle-right::before {
  content: "\e640";
}

.solicon-arrow-circle-up::before {
  content: "\e641";
}

.solicon-arrow-down::before {
  content: "\e642";
}

.solicon-arrow-left::before {
  content: "\e643";
}

.solicon-arrow-right::before {
  content: "\e644";
}

.solicon-arrow-top-left::before {
  content: "\e645";
}

.solicon-arrow-top-right::before {
  content: "\e646";
}

.solicon-arrow-up::before {
  content: "\e647";
}

.solicon-arrow::before {
  content: "\e648";
}

.solicon-arrows-corner::before {
  content: "\e649";
}

.solicon-arrows-horizontal::before {
  content: "\e64a";
}

.solicon-arrows-vertical::before {
  content: "\e64b";
}

.solicon-back-left::before {
  content: "\e64c";
}

.solicon-back-right::before {
  content: "\e64d";
}

.solicon-bag::before {
  content: "\e64e";
}

.solicon-bar-chart-alt::before {
  content: "\e64f";
}

.solicon-bar-chart::before {
  content: "\e650";
}

.solicon-basketball::before {
  content: "\e651";
}

.solicon-bell::before {
  content: "\e603";
}

.solicon-blackboard::before {
  content: "\e652";
}

.solicon-bolt-alt::before {
  content: "\e653";
}

.solicon-bolt::before {
  content: "\e654";
}

.solicon-book::before {
  content: "\e655";
}

.solicon-website::before {
  content: "\e656";
}

.solicon-bookmark::before {
  content: "\e657";
}

.solicon-briefcase::before {
  content: "\e658";
}

.solicon-brush-alt::before {
  content: "\e659";
}

.solicon-brush::before {
  content: "\e65a";
}

.solicon-calendar::before {
  content: "\e65b";
}

.solicon-camera::before {
  content: "\e65c";
}

.solicon-car::before {
  content: "\e65d";
}

.solicon-check-box::before {
  content: "\e65e";
}

.solicon-check::before {
  content: "\e65f";
}

.solicon-clip::before {
  content: "\e660";
}

.solicon-clipboard::before {
  content: "\e661";
}

.solicon-close2::before {
  content: "\e662";
}

.solicon-cloud-down::before {
  content: "\e620";
}

.solicon-cloud-up::before {
  content: "\e625";
}

.solicon-cloud::before {
  content: "\e663";
}

.solicon-comment-alt::before {
  content: "\e604";
}

.solicon-comment::before {
  content: "\e664";
}

.solicon-comments-smiley::before {
  content: "\e665";
}

.solicon-comments::before {
  content: "\e666";
}

.solicon-control-backward::before {
  content: "\e667";
}

.solicon-control-eject::before {
  content: "\e668";
}

.solicon-control-forward::before {
  content: "\e669";
}

.solicon-control-pause::before {
  content: "\e66a";
}

.solicon-control-play::before {
  content: "\e60d";
}

.solicon-control-record::before {
  content: "\e66b";
}

.solicon-control-shuffle::before {
  content: "\e66c";
}

.solicon-control-skip-backward::before {
  content: "\e66d";
}

.solicon-control-skip-forward::before {
  content: "\e66e";
}

.solicon-control-stop::before {
  content: "\e66f";
}

.solicon-credit-card2::before {
  content: "\e626";
}

.solicon-crown::before {
  content: "\e670";
}

.solicon-css3::before {
  content: "\e671";
}

.solicon-cup::before {
  content: "\e672";
}

.solicon-cut::before {
  content: "\e673";
}

.solicon-dashboard::before {
  content: "\e674";
}

.solicon-desktop::before {
  content: "\e675";
}

.solicon-direction-alt::before {
  content: "\e676";
}

.solicon-direction::before {
  content: "\e677";
}

.solicon-download::before {
  content: "\e678";
}

.solicon-dribbble::before {
  content: "\e679";
}

.solicon-email::before {
  content: "\e67d";
}

.solicon-envelope::before {
  content: "\e67e";
}

.solicon-eraser::before {
  content: "\e67f";
}

.solicon-exchange-vertical::before {
  content: "\e680";
}

.solicon-export::before {
  content: "\e681";
}

.solicon-eye::before {
  content: "\e682";
}

.solicon-face-sad::before {
  content: "\e683";
}

.solicon-face-smile::before {
  content: "\e684";
}

.solicon-facebook::before {
  content: "\e614";
}

.solicon-file::before {
  content: "\e685";
}

.solicon-files::before {
  content: "\e686";
}

.solicon-filter::before {
  content: "\e687";
}

.solicon-flags-alt::before {
  content: "\e688";
}

.solicon-flag-alt::before {
  content: "\e689";
}

.solicon-flag::before {
  content: "\e68a";
}

.solicon-folder::before {
  content: "\e68d";
}

.solicon-fullscreen::before {
  content: "\e68e";
}

.solicon-gallery::before {
  content: "\e68f";
}

.solicon-game::before {
  content: "\e690";
}

.solicon-gift::before {
  content: "\e691";
}

.solicon-github::before {
  content: "\e692";
}

.solicon-google::before {
  content: "\e693";
}

.solicon-hand-drag::before {
  content: "\e694";
}

.solicon-hand-open::before {
  content: "\e695";
}

.solicon-hand-point-down::before {
  content: "\e696";
}

.solicon-hand-point-left::before {
  content: "\e697";
}

.solicon-hand-point-right::before {
  content: "\e698";
}

.solicon-hand-point-up::before {
  content: "\e699";
}

.solicon-hand-stop::before {
  content: "\e69a";
}

.solicon-harddrive::before {
  content: "\e69b";
}

.solicon-harddrives::before {
  content: "\e69c";
}

.solicon-headphone-alt::before {
  content: "\e69d";
}

.solicon-headphone::before {
  content: "\e69e";
}

.solicon-heart-broken::before {
  content: "\e615";
}

.solicon-heart::before {
  content: "\e617";
}

.solicon-help-alt::before {
  content: "\e69f";
}

.solicon-help2::before {
  content: "\e6a0";
}

.solicon-home::before {
  content: "\e618";
}

.solicon-html5::before {
  content: "\e6a1";
}

.solicon-hummer::before {
  content: "\e6a2";
}

.solicon-id-badge::before {
  content: "\e627";
}

.solicon-image::before {
  content: "\e6a3";
}

.solicon-import::before {
  content: "\e6a4";
}

.solicon-infinite::before {
  content: "\e6a5";
}

.solicon-info-alt::before {
  content: "\e6a6";
}

.solicon-info::before {
  content: "\e6a7";
}

.solicon-ink-pen::before {
  content: "\e6a8";
}

.solicon-instagram::before {
  content: "\e6a9";
}

.solicon-Italic::before {
  content: "\e6aa";
}

.solicon-joomla::before {
  content: "\e6ab";
}

.solicon-jsfiddle::before {
  content: "\e6ac";
}

.solicon-key::before {
  content: "\e6ad";
}

.solicon-layers-alt::before {
  content: "\e6ae";
}

.solicon-layers2::before {
  content: "\e6af";
}

.solicon-layout-accordion-list::before {
  content: "\e6b0";
}

.solicon-layout-accordion-merged::before {
  content: "\e6b1";
}

.solicon-layout-accordion-separated::before {
  content: "\e6b2";
}

.solicon-layout-column2-alt::before {
  content: "\e6b3";
}

.solicon-layout-column2::before {
  content: "\e6b4";
}

.solicon-layout-column3-alt::before {
  content: "\e6b5";
}

.solicon-layout-column3::before {
  content: "\e6b6";
}

.solicon-layout-column4-alt::before {
  content: "\e6b7";
}

.solicon-layout-column4::before {
  content: "\e6b8";
}

.solicon-layout-cta-btn-left::before {
  content: "\e6b9";
}

.solicon-layout-cta-btn-right::before {
  content: "\e6ba";
}

.solicon-layout-cta-center::before {
  content: "\e6bb";
}

.solicon-layout-cta-left::before {
  content: "\e6bc";
}

.solicon-layout-cta-right::before {
  content: "\e6bd";
}

.solicon-layout-grid2-alt::before {
  content: "\e6be";
}

.solicon-layout-grid2-thumb::before {
  content: "\e6bf";
}

.solicon-layout-grid2::before {
  content: "\e6c0";
}

.solicon-layout-grid3-alt::before {
  content: "\e6c1";
}

.solicon-layout-grid3::before {
  content: "\e6c2";
}

.solicon-layout-grid4-alt::before {
  content: "\e6c3";
}

.solicon-layout-grid4::before {
  content: "\e6c4";
}

.solicon-layout-line-solid::before {
  content: "\e6c5";
}

.solicon-layout-list-large-image::before {
  content: "\e6c6";
}

.solicon-layout-list-post::before {
  content: "\e6c7";
}

.solicon-layout-list-thumb-alt::before {
  content: "\e6c8";
}

.solicon-layout-list-thumb::before {
  content: "\e6c9";
}

.solicon-layout-media-center-alt::before {
  content: "\e6ca";
}

.solicon-layout-media-center::before {
  content: "\e6cb";
}

.solicon-layout-media-left-alt::before {
  content: "\e6cc";
}

.solicon-layout-media-left::before {
  content: "\e6cd";
}

.solicon-layout-media-overlay-alt-2::before {
  content: "\e6ce";
}

.solicon-layout-media-overlay-alt::before {
  content: "\e6cf";
}

.solicon-layout-media-overlay::before {
  content: "\e6d0";
}

.solicon-layout-media-right-alt::before {
  content: "\e6d1";
}

.solicon-layout-media-right::before {
  content: "\e6d2";
}

.solicon-layout-menu-full::before {
  content: "\e6d3";
}

.solicon-layout-menu-separated::before {
  content: "\e6d4";
}

.solicon-layout-menu-v::before {
  content: "\e6d5";
}

.solicon-layout-menu::before {
  content: "\e6d6";
}

.solicon-layout-placeholder::before {
  content: "\e6d7";
}

.solicon-layout-sidebar-2::before {
  content: "\e6d8";
}

.solicon-layout-sidebar-left::before {
  content: "\e6d9";
}

.solicon-layout-sidebar-none::before {
  content: "\e6da";
}

.solicon-layout-sidebar-right::before {
  content: "\e6db";
}

.solicon-layout-slider-alt::before {
  content: "\e6dc";
}

.solicon-layout-slider::before {
  content: "\e6dd";
}

.solicon-layout-tab-min::before {
  content: "\e6de";
}

.solicon-layout-tab-v::before {
  content: "\e6df";
}

.solicon-layout-tab-window::before {
  content: "\e6e0";
}

.solicon-layout-tab::before {
  content: "\e6e1";
}

.solicon-layout-width-default-alt::before {
  content: "\e6e2";
}

.solicon-layout-width-default::before {
  content: "\e6e3";
}

.solicon-layout-width-full::before {
  content: "\e6e4";
}

.solicon-layout::before {
  content: "\e6e5";
}

.solicon-light-bulb::before {
  content: "\e6e6";
}

.solicon-line-dashed::before {
  content: "\e6e7";
}

.solicon-line-dotted::before {
  content: "\e6e8";
}

.solicon-line-double::before {
  content: "\e6e9";
}

.solicon-link::before {
  content: "\e619";
}

.solicon-linkedin::before {
  content: "\e6ea";
}

.solicon-linux::before {
  content: "\e6eb";
}

.solicon-list-ol::before {
  content: "\e6ec";
}

.solicon-list::before {
  content: "\e6ed";
}

.solicon-location-arrow::before {
  content: "\e6ee";
}

.solicon-location-pin::before {
  content: "\e6ef";
}

.solicon-lock::before {
  content: "\e6f0";
}

.solicon-loop::before {
  content: "\e6f1";
}

.solicon-magnet::before {
  content: "\e6f2";
}

.solicon-map-alt::before {
  content: "\e6f3";
}

.solicon-map::before {
  content: "\e6f4";
}

.solicon-marker-alt::before {
  content: "\e6f5";
}

.solicon-marker::before {
  content: "\e6f6";
}

.solicon-medall-alt::before {
  content: "\e6f7";
}

.solicon-medall::before {
  content: "\e6f8";
}

.solicon-menu-alt::before {
  content: "\e6f9";
}

.solicon-menu::before {
  content: "\e6fa";
}

.solicon-microphone-alt::before {
  content: "\e6fb";
}

.solicon-microphone2::before {
  content: "\e6fc";
}

.solicon-microsoft-alt::before {
  content: "\e6fd";
}

.solicon-microsoft::before {
  content: "\e6fe";
}

.solicon-minus::before {
  content: "\e6ff";
}

.solicon-mobile::before {
  content: "\e700";
}

.solicon-money::before {
  content: "\e701";
}

.solicon-more-alt::before {
  content: "\e61a";
}

.solicon-more::before {
  content: "\e61b";
}

.solicon-mouse-alt::before {
  content: "\e702";
}

.solicon-mouse::before {
  content: "\e703";
}

.solicon-music-alt::before {
  content: "\e61c";
}

.solicon-music::before {
  content: "\e61d";
}

.solicon-na::before {
  content: "\e704";
}

.solicon-new-window::before {
  content: "\e705";
}

.solicon-notepad::before {
  content: "\e706";
}

.solicon-package::before {
  content: "\e707";
}

.solicon-paint-bucket::before {
  content: "\e708";
}

.solicon-paint-roller::before {
  content: "\e709";
}

.solicon-palette::before {
  content: "\e70a";
}

.solicon-panel::before {
  content: "\e70b";
}

.solicon-paragraph::before {
  content: "\e70c";
}

.solicon-pencil-alt::before {
  content: "\e61e";
}

.solicon-pencil-alt2::before {
  content: "\e70d";
}

.solicon-pencil::before {
  content: "\e70e";
}

.solicon-pie-chart::before {
  content: "\e70f";
}

.solicon-pin-alt::before {
  content: "\e710";
}

.solicon-pin::before {
  content: "\e711";
}

.solicon-pin2::before {
  content: "\e712";
}

.solicon-pinterest-alt::before {
  content: "\e713";
}

.solicon-pinterest::before {
  content: "\e714";
}

.solicon-plug::before {
  content: "\e715";
}

.solicon-plus::before {
  content: "\e716";
}

.solicon-power-off::before {
  content: "\e717";
}

.solicon-printer::before {
  content: "\e718";
}

.solicon-pulse::before {
  content: "\e719";
}

.solicon-quote-left::before {
  content: "\e71a";
}

.solicon-quote-right::before {
  content: "\e71b";
}

.solicon-receipt::before {
  content: "\e71c";
}

.solicon-reddit::before {
  content: "\e71d";
}

.solicon-reload::before {
  content: "\e71e";
}

.solicon-rocket::before {
  content: "\e71f";
}

.solicon-rss-alt::before {
  content: "\e720";
}

.solicon-rss::before {
  content: "\e721";
}

.solicon-ruler-alt-2::before {
  content: "\e722";
}

.solicon-ruler-alt::before {
  content: "\e723";
}

.solicon-ruler-pencil::before {
  content: "\e724";
}

.solicon-ruler::before {
  content: "\e725";
}

.solicon-save-alt::before {
  content: "\e726";
}

.solicon-save::before {
  content: "\e727";
}

.solicon-search::before {
  content: "\e61f";
}

.solicon-server::before {
  content: "\e728";
}

.solicon-settings::before {
  content: "\e729";
}

.solicon-share-alt::before {
  content: "\e72a";
}

.solicon-share2::before {
  content: "\e72b";
}

.solicon-sharethis-alt::before {
  content: "\e72c";
}

.solicon-sharethis::before {
  content: "\e72d";
}

.solicon-shield::before {
  content: "\e72e";
}

.solicon-shift-left-alt::before {
  content: "\e72f";
}

.solicon-shift-left::before {
  content: "\e730";
}

.solicon-shift-right-alt::before {
  content: "\e731";
}

.solicon-shift-right::before {
  content: "\e732";
}

.solicon-shine::before {
  content: "\e733";
}

.solicon-shopping-cart-full::before {
  content: "\e628";
}

.solicon-shopping-cart-full2::before {
  content: "\e621";
}

.solicon-shopping-cart::before {
  content: "\e629";
}

.solicon-shopping-cart2::before {
  content: "\e734";
}

.solicon-shortcode::before {
  content: "\e735";
}

.solicon-signal::before {
  content: "\e736";
}

.solicon-slice::before {
  content: "\e738";
}

.solicon-smallcap::before {
  content: "\e739";
}

.solicon-soundcloud::before {
  content: "\e73a";
}

.solicon-split-h::before {
  content: "\e73b";
}

.solicon-split-v-alt::before {
  content: "\e73c";
}

.solicon-split-v::before {
  content: "\e73d";
}

.solicon-spray::before {
  content: "\e73e";
}

.solicon-stack-overflow::before {
  content: "\e73f";
}

.solicon-stamp::before {
  content: "\e740";
}

.solicon-star::before {
  content: "\e741";
}

.solicon-stats-down::before {
  content: "\e742";
}

.solicon-stats-up::before {
  content: "\e743";
}

.solicon-support::before {
  content: "\e744";
}

.solicon-tablet::before {
  content: "\e745";
}

.solicon-tag::before {
  content: "\e622";
}

.solicon-target::before {
  content: "\e746";
}

.solicon-text::before {
  content: "\e747";
}

.solicon-thought::before {
  content: "\e74b";
}

.solicon-thumb-down::before {
  content: "\e74c";
}

.solicon-thumb-up::before {
  content: "\e74d";
}

.solicon-ticket::before {
  content: "\e74e";
}

.solicon-time::before {
  content: "\e74f";
}

.solicon-timer::before {
  content: "\e750";
}

.solicon-trash::before {
  content: "\e623";
}

.solicon-trello::before {
  content: "\e751";
}

.solicon-truck::before {
  content: "\e752";
}

.solicon-twitter-alt::before {
  content: "\e624";
}

.solicon-twitter::before {
  content: "\e755";
}

.solicon-underline::before {
  content: "\e756";
}

.solicon-unlink::before {
  content: "\e757";
}

.solicon-unlock::before {
  content: "\e758";
}

.solicon-upload::before {
  content: "\e759";
}

.solicon-uppercase::before {
  content: "\e75a";
}

.solicon-user::before {
  content: "\e75b";
}

.solicon-vector::before {
  content: "\e75c";
}

.solicon-video-camera::before {
  content: "\e75d";
}

.solicon-video-clapper::before {
  content: "\e75e";
}

.solicon-view-grid::before {
  content: "\e75f";
}

.solicon-view-list-alt::before {
  content: "\e760";
}

.solicon-view-list::before {
  content: "\e761";
}

.solicon-volume::before {
  content: "\e764";
}

.solicon-wallet::before {
  content: "\e765";
}

.solicon-wand::before {
  content: "\e766";
}

.solicon-wheelchair::before {
  content: "\e767";
}

.solicon-widget-alt::before {
  content: "\e768";
}

.solicon-widget::before {
  content: "\e769";
}

.solicon-widgetized::before {
  content: "\e76a";
}

.solicon-window::before {
  content: "\e76b";
}

.solicon-world::before {
  content: "\e76d";
}

.solicon-write::before {
  content: "\e76e";
}

.solicon-yahoo::before {
  content: "\e76f";
}

.solicon-youtube::before {
  content: "\e62a";
}

.solicon-zip::before {
  content: "\e770";
}

.solicon-zoom-in::before {
  content: "\e771";
}

.solicon-zoom-out::before {
  content: "\e772";
}

.solicon-help::before {
  content: "\f059";
}

.solicon-code::before {
  content: "\f121";
}

.solicon-dollar::before {
  content: "\f155";
}

.solicon-yen::before {
  content: "\f157";
}

.solicon-globe::before {
  content: "\e60f";
}

.solicon-stop::before {
  content: "\e612";
}

.solicon-play-o::before {
  content: "\e611";
}

.solicon-logo::before {
  content: "\e600";
}

.solicon-close-o::before {
  content: "\e601";
}

.solicon-close::before {
  content: "\e602";
}

.solicon-repeat::before {
  content: "\e605";
}

.solicon-shuffle::before {
  content: "\e606";
}

.solicon-spearker::before {
  content: "\e607";
}

.solicon-forward::before {
  content: "\e608";
}

.solicon-backward::before {
  content: "\e609";
}

.solicon-pause::before {
  content: "\e60a";
}

.solicon-play::before {
  content: "\e60b";
}

.solicon-playlist::before {
  color: #b3b3b1;
  content: "\e60c";
}

.solicon-price-tag::before {
  content: "\e903";
}

.solicon-share::before {
  content: "\e616";
}

.solicon-library::before {
  content: "\e921";
}

.solicon-spinner::before {
  content: "\e981";
}
