//
// Visibility utilities
//

.visible {
  @include invisible(visible);
}

.invisible {
  @include invisible(hidden);
}

.hidden-sm {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
