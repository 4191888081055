@import "styles/variables";
@import "styles/mixins";

.footer {
  padding: 3rem 0 5rem;
  font-size: .875rem;
  color: $gray-light;
  background: $gish;

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}

.ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.li {
  position: relative;
  display: inline-block;

  &::after {
    display: inline-block;
    padding-right: .5rem;
    padding-left: .5rem;
    color: $gray;
    content: "|";
  }

  &:last-child::after {
    content: "";
  }
}

.link {
  color: $gray-light;
  text-decoration: none;
  background: none;
  border: 0;

  &:hover,
  &:focus {
    color: $white;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
  }
}

.locale {
  background: transparent;
}

@media (min-width: $screen-md) {
  .mdRight {
    text-align: right;
  }
}

.hr {
  margin: 1rem 0;
  border: 0;
  border-top: 1px solid $g600;
}
