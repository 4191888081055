// stylelint-disable selector-no-qualifying-type
@import "styles/variables";

.header {
  position: absolute;
  top: 0;
  z-index: $z4;
  width: 100%;
  height: $navbar-height;
  font-size: .875rem;
  line-height: $navbar-height;
  background-color: $white;
  box-shadow: 0 2px 2px -2px rgb(0 0 0 / 15%);

  .link {
    line-height: 2;
    color: $black;
    text-decoration: none;
  }
}

.inverse {
  background-color: $black;
  box-shadow: none;

  .link {
    color: $white;
  }
}

.navSection {
  display: flex;
  flex: 1 0 0;
  align-items: center;
}

.navCenter {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}

.nav {
  display: flex;
  padding-left: 0;
  margin-left: 0;
  list-style: none;

  > li + li {
    margin-left: 1rem;
  }
}

.menu {
  display: block;
  width: 350px;
  max-width: 100%;
  margin-top: 1px;
  line-height: 1;
  background: $white;
  box-shadow: $box-shadow-sm;
}

.profile {
  background: $g100;
}

.viewProfile {
  padding: .25rem 1rem;
  text-decoration: none;
  border: 1px solid $g600;
  border-radius: 99em;
  transition: $transition-base;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    text-decoration: none;
    background: $g900;
    border: 1px solid $g900;
  }
}

.divider {
  border-bottom: 1px solid $g200;
}

.menuList {
  padding: 0;
  margin: 0;
  list-style: none;

  a,
  button {
    display: block;
    width: 100%;
    padding: 1rem;
    font-size: .875rem;
    line-height: 1;
    color: $black;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      background-color: $g100;
      outline: 0;
    }

    &.sm {
      padding: .75rem 1rem;
      font-size: .85rem;
    }
  }
}

.menuHeader {
  padding: 1rem 1rem .5rem;
  font-size: .85rem;
  font-weight: 600;
  line-height: 1;
  color: $g600;
}

.btn {
  height: 2rem;
  padding: 0 1rem;
  line-height: 2rem;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
