@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "mixins";
@import "reboot";
@import "types";
@import "grid";
@import "utilities/align";
@import "utilities/animation";
@import "utilities/block";
@import "utilities/borders";
@import "utilities/buttons";
@import "utilities/clearfix";
@import "utilities/cookie";
@import "utilities/display";
@import "utilities/embed";
@import "utilities/flex";
@import "utilities/float";
@import "utilities/list";
@import "utilities/position";
@import "utilities/screenreaders";
@import "utilities/shadows";
@import "utilities/spacing";
@import "utilities/text";
@import "utilities/visibility";
@import "nprogress";
@import "../node_modules/draft-js-emoji-plugin/lib/plugin.css";
// @import "draft-js-hashtag-plugin/lib/plugin.css";
// @import "draft-js-mention-plugin/lib/plugin.css";


.surface {
  transition: $transition-base;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}
