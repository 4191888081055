html {
  font-size: 14px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
}
h2 { font-size: 1.75rem; }
h3 { font-size: 1.5rem; }
h4 { font-size: 1rem; }
h5 { font-size: .875rem; }

p { font-size: 1rem; }

.heading,
.heading-sm {
  margin-bottom: 2rem;
  border-bottom: 1px solid $g400;
}

.heading {
  .text {
    display: inline-block;
    padding-bottom: 1.25rem;
    margin-bottom: -1px;
    font-size: 1.25rem;
    font-weight: 600;
    border-bottom: 1px solid $g800;
  }
}

.heading-sm {
  .text {
    display: inline-block;
    padding-bottom: 1rem;
  }
}

.help-block {
  font-size: .875rem;
  color: $g600;
}
