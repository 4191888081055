// stylelint-disable selector-no-qualifying-type, declaration-no-important
@import "styles/variables";
@import "styles/mixins";

.btn {
  display: inline-block;
  padding: .5rem 1.5rem;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.25;
  color: $black;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid $g600;
  border-radius: 999em;
  transition: .2s ease-in-out;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgb(0 123 255 / 25%);
  }

  &:disabled {
    cursor: default;
    opacity: .65;
  }

  &:active {
    background-image: none;
  }

  &:not([disabled]) {
    &:hover {
      color: $white !important;
      background-color: $gray-dark;
      border-color: $gray-dark;
    }

    &.focus,
    &:focus {
      box-shadow: 0 0 0 3px rgb(134 142 150 / 50%);
    }

    &.disabled,
    &:disabled {
      opacity: .6;
    }

    &.active,
    &:active {
      background-color: #727b84;
      background-image: none;
      border-color: #6c757d;
    }
  }
}

.sm {
  padding: .25rem 1rem;
  font-size: 12px;
}

.lg {
  padding: .5rem 3rem;
  // font-size: 1rem;
}

.xl {
  height: 2.5rem;
  padding: .75rem 1.5rem;
  line-height: 1.25;

  @include media-breakpoint-up(md) {
    height: 3rem;
    padding: .75rem 3rem;
    font-size: 1.125rem;
  }
  @include media-breakpoint-up(xl) {
    height: 3rem;
    padding: .75rem 3rem;
  }
}

.block {
  display: block;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.primary {
  @include theme-gradient(0);
  color: $brand-primary;
  border-color: $brand-primary;

  &:not([disabled]) {
    &:hover {
      @include theme-gradient(1);
      border-color: $brand-primary;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 3px rgba($brand-primary, .3);
    }

    &:active,
    &.active {
      color: $white !important;
      @include theme-gradient(1);
      border-color: $brand-primary;
    }

    &.clicked {
      box-shadow: none;
      animation-name: ripple-primary;
      animation-duration: .4s;
    }
  }
}


.primary-alt {
  color: $white !important;
  background: $white;
  background-position: 0 0;
  background-size: 200% 100%;
  border-color: $brand-primary;
  @include theme-gradient(1);

  &:not([disabled]) {
    &:hover {
      // @include theme-gradient(.8);
      color: $white !important;
      @include theme-gradient(1);
      background-position: 100% 0;
      border-color: $brand-primary;
    }

    &:focus,
    &.focus {
      @include theme-gradient(1);
      box-shadow: 0 0 0 3px rgba($brand-primary, .3);
    }

    &:active,
    &.active {
      color: $white !important;
      border-color: $brand-primary;
    }
  }
}

.link {
  color: $link-color;
  background: transparent;
  border: 0;

  &:not([disabled]) {
    &:hover,
    &.focus,
    &:focus,
    &.active,
    &:active {
      color: $link-hover-color !important;
      text-decoration: underline;
      background: transparent;
      border: 0;
      box-shadow: none;
    }
  }
}
